<app-header></app-header>
<section class="defualt_bg">

        <div class="container">
            <div class="row">
                <div class="col-md-12">
               <h1 class="text-center mt-4 text-light bar_bottom">Pricing & Plans</h1>
               <h4 style="text-align: center; color:#fff;">Limited Time Offer - 50% Discount</h4>
                </div>
             </div>
             <div class="row">
              <div class="col-md-12">
                <div *ngIf="packageLoading" class="text-center">
                  <div class="spinner-border text-light" role="status">
                  </div>
                </div>
                <owl-carousel-o [options]="customOptions">
                  <ng-template carouselSlide *ngFor="let plan of plans">
                    <div class="card text-light bg-dark set_outline" [ngClass]="(planId==plan?.id)?'selected_plan_bg':'not_selected'">
                      <div class="ribbon" *ngIf="planId == plan?.id"><span>Active Plan</span></div>
                      <div class="card-body">
                          <div class="top_wrapper_plan">
                        <h4 class="card-title text-center p_he">
                          {{plan?.plan_name}}</h4>
                          <small>{{plan?.reoccurring}}</small>
                          <h3 class="card-subtitle mb-2 text-warning text-center"><del *ngIf="plan.price !=0">${{plan.price*2}}</del> <br *ngIf="plan.price !=0"> ${{plan.price}}</h3>
                        <h6><span class="text-uppercase">{{plan.duration}}</span></h6>
                      </div>
                      <p class="card-text">{{plan?.info}}</p>
                      <p><b>{{plan?.subTitle}}</b></p>
                        <div class="list-wrapper">
                          <ul>
                              <li *ngFor="let feature of plan?.features">{{feature?.feature_title}}</li>
                         </ul>
                        </div>
                        <p class="text-center" *ngIf="planId != plan?.id && !userID"> <a href="javascript:void(0);" (click)="askForLogInOrRegister(plan.id);" class="btn cu_btn btn-warning">{{planButton}}</a></p>

                        <p class="text-center" *ngIf="planId != plan?.id && userID"> <a (click)="goToCheckout(plan?.id)" class="btn cu_btn btn-warning">{{planButton}}</a></p>
                      </div>
                    </div>
                  </ng-template>
              </owl-carousel-o>
              </div>
             </div>
    </div>
</section>

  <!-- show terms popup -->
  <ng-template #askForLogin  let-close="close">
    <button type="button" class="close" (click)="askForLogInOrRegisterClose()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body bg-dark modal_body_show ask_for_login" id="_pop_up_login" >
      <div class="row">
        <div class="col-md-12">
        <h5 class="text-light text-center mt-3 mb-3">You have an Account?</h5>
        <p class="text-center"><button type="button" class="btn btn-warning btn-sm" (click)="goToLogin()">Login Now</button></p>
        <h5 class="text-center text-light ">If you don't have an account?</h5>
        <p class="text-center"><button type="button" class="btn btn-success btn-sm"  (click)="goToRegister()">Register Now</button></p>
    </div>
  </div>
    </div>

  </ng-template>


    
