import { DataService } from './../_services/dataShare';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNavigationComponent } from './user-navigation/user-navigation.component';
import { UserRoutingModule } from '../userDashboard/user-routing.module';
import { TopBannerWrapperComponent } from './top-banner-wrapper/top-banner-wrapper.component';
import { CommentsComponent } from './comments/comments.component';
import { SwingerCommentsComponent } from './swinger-comments/swinger-comments.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SearchPlaceComponent } from './search-place/search-place.component';
import { AgmCoreModule } from '@agm/core';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { InvoceComponent } from './invoce/invoce.component';
import { SubscribePlanComponent } from './subscribe-plan/subscribe-plan.component';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCJO17hr0x0XPD8fhY4WxgxGb1muNBEhfE',
      libraries: ['places']
    }),
  ],
  declarations: [
    UserNavigationComponent,
    TopBannerWrapperComponent,
    CommentsComponent,
    SwingerCommentsComponent,
    UserMenuComponent,
    SearchPlaceComponent,
    DashboardHeaderComponent,
    InvoceComponent,
    SubscribePlanComponent,
  ],
  exports: [UserNavigationComponent,
    TopBannerWrapperComponent,
    CommentsComponent,
    SwingerCommentsComponent,
    UserMenuComponent,
    SearchPlaceComponent,
    DashboardHeaderComponent,
    InvoceComponent,
    SubscribePlanComponent
   ],   
   providers: [
  DataService,

],
})
export class SharingModuleModule { }
